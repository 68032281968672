<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="4"></top>
      <div class="container-box">
        <p class="title fs-super-big black fw-mid margin-b textAlign-c">{{ data.title }}</p>
        <p class="info textAlign-c">
          <span>来源：{{ data.source || '瑞安版权交易服务平台' }}</span>
          <span>浏览次数：{{ data.viewsNum }}</span>
          <span>发表日期：{{ data.pubdate }}</span>
        </p>
        <div v-if="data.image" class="width-100 textAlign-c picture">
          <el-image class="width-80" :src="imagePrefix + data.image" fit="cover"></el-image>
        </div>
        <p v-if="data.content" class="fs-big detail text-indent darkgrey" v-html="data.content"></p>
        <p v-else class="fs-big detail text-indent darkgrey">{{ data.blurb }}</p>
      </div>
    </div>
    <bottom :bgColor="'#F3F9FF'"></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { articleInfo } from '@/api/TranArticle';

export default {
  name: 'informationDetail',
  components: {
    top,
    bottom
  },
  data() {
    return {
      imagePrefix: process.env.VUE_APP_IMAGE_PREFIX,
      data: {},
      info: {
        title: '补齐西部山区交通短板 泰顺通用机场项目开工 ',
        picture: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        detail:
          '近日，温州大学继续教育学院院长孙广福先生、温州大学继续教育学院副院长陈坤党先生、 温州大学继续教育学院培训发展一部主任李伊白女温州大学继续教育学院培训发展一部主任李伊白女温州大学继续教育学院培训发展一部主任李伊白女温州大学继续教育学院培训发展一部主任李伊白女温州大学继续教育学院培训发展一部主任李伊白女温州大学继续教育学院培训发展一部主任李伊白女',
        month: '2023-12',
        day: '24',
        reader: 654,
        from: '中国知识产权资讯网'
      }
    };
  },
  created() {
    articleInfo(this.$route.query.id).then((res) => {
      this.data = res.data;
    });
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.container-box {
  padding: 40px 0 60px;

  .info {
    span {
      margin: 0 30px;
      font-size: 18px;
      color: #8c939d;
    }
  }

  .picture {
    margin: 60px 0;
  }

  .detail {
    line-height: 36px;
  }
}
</style>
